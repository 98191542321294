* {
  font-family: "Segoe UI", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  // background-color: #ebedef !important;
}

.fg-green {
  color: green;
}
.fg-silver {
  color: silver;
}

.card {
  width: 100%;
  border: 1px solid;
  border-radius: 0.25rem #d9d9da;
  background-color: #fff;
  margin-bottom: 1rem;

  .card-body {
    padding: 1rem;
  }
  .card-footer {
    padding: 1rem;
  }
}

.navbar {
  margin-bottom: 1rem;
}

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1540px !important;
  }
}

.chartjs-tooltip {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0.25rem;
  color: #fff;
  opacity: 0;
  padding: 0.25rem 0.5rem;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  transition: all 0.15s ease;
  z-index: 1021;
  min-width: 170px;
}

.pull-right {
  float: right;
}

.mr-3 {
  margin-right: 1rem !important;
}
.mb-6 {
  margin-bottom: 2rem !important;
}

.hidden {
  display: none !important;
}

.prediction {
  padding: 15px;
  border: 1px solid gray;
  border-radius: 5%;

  .realtime {
    color: green;
  }
}
.colorBox {
  width: 25px;
  height: 25px;
}

.route {
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.route-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 5px;
  filter: grayscale(100%) brightness(1000%);
}

.row-hover:hover {
  background-color: #d9d9da;
}

.text-right {
  text-align: right;
}
.pd-r-15 {
  padding-right: 15px;
}
.my-table {
  line-break: anywhere;

  td {
    padding-bottom: 8px;
  }
}
.route-status {
  width: 30px;
  display: inline-block;
}

.cursor-hand {
  cursor: pointer;
}

.map-container {
  width: 100%;
  height: 700px;
}
.bg-development {
  background-color: #b07e38;
}
